<template>
  <div>
    <!-- Banner -->
    <section class="banner">
        <div class="content">
        <h1>Server Corp.</h1>
        <p>クラウドで、世界を、もっと、働きやすく</p>
        </div>
    </section>
    <!-- News -->
    <section class="wrapper">
        <h1>お知らせ</h1>
        <div class="news">
        <div class="news-item">
            <div class="image">
            <img src="../assets/img/pic02.jpg" alt="" >
            </div>
            <div class="content">
            <h2>最新ニュース１</h2>
            <p>新バージョンのCAがリリースしました</p>
            </div>
        </div>
        <div class="news-item">
            <div class="image">
            <img src="../assets/img/pic03.jpg" alt="" />
            </div>
            <div class="content">
            <h2>最新ニュース２</h2>
            <p>運用代行サービス開始</p>
            </div>
        </div>
        <div class="news-item">
            <div class="image">
            <img src="../assets/img/pic04.jpg" alt="" />
            </div>
            <div class="content">
            <h2>最新ニュース３</h2>
            <p>社長就任</p>
            </div>
        </div>
        </div>
    </section>    
    </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>

<style scoped>
@charset "UTF-8";
@import "https://fonts.googleapis.com/css?family=Montserrat:700|Open+Sans:300,400|Pacifico";

.banner {
  padding: 300px 0 150px 0;
  background-image: url(../assets/img/banner.jpg);
  background-color: rgba(255,255,255,0.7);
  background-blend-mode: lighten;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  text-align: center;
}

.banner h1 {
  font-size: 3em;
  font-weight: 700;
  color: #c50e2f;
  line-height: 1em;
  margin: 0 0 0.5em 0;
  padding: 0;
}

.banner p {
  font-size: 1.25em;
  font-family: Arial,sans-serif;
  font-weight: 400;
  color: black;
  margin-bottom: 1.75em;
}

.wrapper {
  padding: 6em 0 4em 0;
  text-align: center;
}

.wrapper h1 {
  text-align: center;
  padding: 30px 0px;
  font-size: 40px;
  font-weight: bold;
  font-family: sans-serif;
}

.wrapper h2 {
  font-weight: bold;
}

.wrapper h1, .wrapper h2 {
  color: #c50e2f;
}

.news {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.news-item {
  width: 30%;
}

.content {
  text-align: center;
  box-sizing: content-box;
}
</style>